import React from "react";
import { Link } from "react-router-dom";
import { history } from "../../App";
import Burger from "./Burger";
import Logo from "../../assets/Aviro_Logo.svg";
import NDOH from "../../assets/NDoH_logo.png";
import "../styles/TopNavigation.css";

const TopNavigation = ({ formSetter, menuTrigger, burger }) => {
    // Check if user has previously visited the site
    // If so, create a link to the hiv-content
    const prev_user = localStorage.getItem("engage_token") ? (
        <Link to='/hiv-content'>
            <p className='nav_p'>HIV-CONTENT</p>
        </Link>
    ) : null;

    // To decide className's based on 'prev_user'
    const allowHiv = prev_user ? "" : "_newuser";

    // Navigation helper for Aviro logo
    const navHelper = () => {
        if (history.location.pathname === "/") {
            formSetter("Landing");
        } else {
            history.push("/");
        }
    };

    // Burger animation function
    const renderBurger = () => {
        if (burger) {
            return <Burger from='0deg' to='90deg' menuTrigger={menuTrigger} />;
        } else {
            return <Burger from='90deg' to='0deg' menuTrigger={menuTrigger} />;
        }
    };

    return (
        <nav>
            <div>
                <img
                    src={Logo}
                    alt='Aviro'
                    className='nav_logo'
                    onClick={navHelper}
                />
                <img src={NDOH} alt='NDOH' className='nav_logo_2' />
            </div>
            <div className={`nav_right_container${allowHiv}`}>
                <div className={`links_container${allowHiv}`}>
                    {prev_user}
                    <Link to='/contact'>
                        <p className='nav_p'>CONTACT</p>
                    </Link>
                </div>
                {renderBurger()}
            </div>
        </nav>
    );
};

export default TopNavigation;
