// This file houses the firebase logic working in the background
// while the app is running

import { history } from "../App";
import firestore from "./firestore";
const db = firestore.firestore();
const auth = firestore.auth();
const analytics = firestore.analytics();
export const storage = firestore.storage();

// Signs user in anonymously so that firestore can be
// interacted with safely
let uid;
export const anonSignIn = () => {
    try {
        // Make sure when tab/browser closes, the user is no longer
        // signed in
        auth.setPersistence(firestore.auth.Auth.Persistence.SESSION).then(
            async () => {
                // Actual sign in
                await auth.signInAnonymously();
                auth.onAuthStateChanged((user) => {
                    uid = user.uid;
                });
            }
        );
    } catch (error) {
        console.log(error);
    }
};

// Takes in the type of user, and the data they just submitted
// Function makes POST to firestore and on success creates an
// "engage_token" within local storage, then redirects user
// to hiv-content route
export const setCurrentUser = async (userType, data) => {
    const collection = userType === "Health" ? "health_users" : "public_users";
    try {
        if (collection === "public_users") {
            if (data.language === "" || data.age === "" || data.gender === "") {
                localStorage.setItem("engage_token", uid);
            } else {
                // Add user to firestore
                await db.collection(collection).add(data);
                // If marketing == Yes, add given email to
                // marketing collection
                if (collection === "health_users" && data.marketing === "Yes") {
                    try {
                        await db
                            .collection("marketing_list")
                            .add({ email: data.email });
                    } catch (error) {
                        console.log(error);
                    }
                }
                // Add token to browser
                localStorage.setItem("engage_token", uid);
                // Log Sign-Up event
                analytics.logEvent("sign_up", {
                    user_type: userType,
                });
            }
        }
    } catch (err) {
        console.log(err);
    }
    // Upon success, redirect user to hiv-content
    history.push("/hiv-content");
};

// Takes in data from contact form
// Function makes POST to firestore
export const sendMessage = async (data) => {
    try {
        // Post message to firestore
        await db.collection("contact_messages").add(data);
        // Check if user wants to be added to newsletter
        if (data.newsletter) {
            try {
                // Add user email to newsletter list
                await db
                    .collection("newsletter_list")
                    .add({ email: data.email });
            } catch (error) {
                console.log(error);
            }
        }
        // Log Contact-Message-Sent sent
        analytics.logEvent("contact_message_sent", {
            agreed_to_newsletter: data.newsletter,
        });
    } catch (error) {
        console.log(error);
    }
    // Upon success, redirect user back to home page
    history.push("/");
};
