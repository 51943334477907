import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Spring } from "react-spring/renderprops";
import "../styles/Menu.css";

const Menu = () => {
    // Link helper function. Decides which menu
    // items to display depending on the existance
    // of a token
    const linkHelper = () => {
        if (!localStorage.getItem("engage_token")) {
            return (
                <Fragment>
                    <Link to='/contact'>
                        <p className='menu_p'>CONTACT</p>
                    </Link>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Link to='/hiv-content'>
                        <p className='menu_p'>HIV-CONTENT</p>
                    </Link>
                    <Link to='/contact'>
                        <p className='menu_p'>CONTACT</p>
                    </Link>
                    {/* <p
                        className='menu_p'
                        onClick={() => {
                            localStorage.removeItem("engage_token");
                            window.location.reload();
                        }}
                    >
                        FORGET ME
                    </p> */}
                </Fragment>
            );
        }
    };

    // Return responsible for displaying UI
    return (
        <Spring
            config={{ duration: 300 }}
            from={{
                opacity: "0",
                transform: "translateX(500px)",
            }}
            to={{ opacity: "1", transform: "translateX(0px)" }}
        >
            {(props) => (
                <div style={props}>
                    <div className='menu_container'>{linkHelper()}</div>;
                </div>
            )}
        </Spring>
    );
};

export default Menu;
