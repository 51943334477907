import React, { useState } from "react";
import { Spring } from "react-spring/renderprops";
import PageFooter from "../UI/PageFooter";
import "../styles/ContactForm.css";

const ContactForm = ({ passDataUp }) => {
    // State for form
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [newsletter, setNewsletter] = useState(true);
    // State for error case
    const [error, setError] = useState(false);

    // Make sure error dissappears if all fields become filled
    if (error) {
        if (name && surname && email && subject && message) {
            setError(false);
        }
    }

    // Submit helper function
    const submitHelper = () => {
        // Check that all fields have been filled
        if (name && surname && email && subject && message) {
            // Create contact object to send to firestore
            const data = {
                name,
                surname,
                email,
                subject,
                message,
                newsletter,
            };
            // Send data up to Contact, where it
            // will be sent to firestore
            passDataUp(data);
        } else {
            setError(true);
        }
    };

    // Newsletter toggle
    // Change css based on state of newsletter
    const yes_newsletter = newsletter ? "-selected" : "";
    const no_newsletter = !newsletter ? "-selected" : "";
    const yes_p = newsletter ? "-selected" : "";
    const no_p = !newsletter ? "-selected" : "";

    return (
        <Spring
            from={{ opacity: "0", transform: "translateX(-500px)" }}
            to={{ opacity: "1", transform: "translateX(0px)" }}
        >
            {(props) => (
                <div className='cnt_container' style={props}>
                    <div className='cnt_form_button_container'>
                        <div className='cnt_content_container'>
                            <h1 className='cnt_h1'>Contact Us</h1>
                            <h2 className='cnt_h2'>
                                Feel free to get in touch for more information:
                            </h2>
                            <h3 className='cnt_h3'>This content was made in partnership with the 
                            Department of Health so that Health Care providers could use this in 
                            their various platforms. If you would like to download this contact for
                             offline viewing please contact <u><a href="mailto:admin@avirohealth.com">admin@avirohealth.com</a></u>.</h3>
                            <div className='cnt_inputs_0'>
                                <input
                                    className={`cnt_info_inputs${
                                        error && name === "" ? "-error" : ""
                                    }`}
                                    type='text'
                                    value={name}
                                    placeholder='Name'
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <input
                                    className={`cnt_info_inputs${
                                        error && surname === "" ? "-error" : ""
                                    }`}
                                    type='text'
                                    value={surname}
                                    placeholder='Surname'
                                    onChange={(e) => setSurname(e.target.value)}
                                />
                            </div>
                            <div className='cnt_inputs_1'>
                                <input
                                    className={`cnt_info_inputs${
                                        error && email === "" ? "-error" : ""
                                    }`}
                                    type='text'
                                    value={email}
                                    placeholder='Email'
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <input
                                    className={`cnt_info_inputs${
                                        error && subject === "" ? "-error" : ""
                                    }`}
                                    type='text'
                                    value={subject}
                                    placeholder='Subject'
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </div>
                            <div className='cnt_inputs_2'>
                                <textarea
                                    className={`cnt_infomessage_inputs${
                                        error && message === "" ? "-error" : ""
                                    }`}
                                    type='text'
                                    value={message}
                                    placeholder='Message'
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                {error ? (
                                    <Spring
                                        config={{ duration: 300 }}
                                        from={{
                                            transform: "translateX(-100px)",
                                            opacity: "0.5",
                                        }}
                                        to={{
                                            transform: "translateX(0px)",
                                            opacity: "1",
                                        }}
                                    >
                                        {(props) => (
                                            <div style={props}>
                                                <p className='cnt_error'>
                                                    Oops, there are empty
                                                    fields. Please complete all
                                                    fields before submitting!
                                                </p>
                                            </div>
                                        )}
                                    </Spring>
                                ) : null}
                                <div className='cnt_newsletter_container'>
                                    <div className='toggle_container'>
                                        <div
                                            className={`toggle_left${yes_newsletter}`}
                                            onClick={() => setNewsletter(true)}
                                        >
                                            <p className={`toggle_p${yes_p}`}>
                                                Yes
                                            </p>
                                        </div>
                                        <div
                                            className={`toggle_right${no_newsletter}`}
                                            onClick={() => setNewsletter(false)}
                                        >
                                            <p className={`toggle_p${no_p}`}>
                                                No
                                            </p>
                                        </div>
                                    </div>
                                    <p className='cnt_p'>
                                        Sign me up for Aviro's news-letter!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <button className='cnt_button' onClick={submitHelper}>
                            SUBMIT
                        </button>
                    </div>
                    <PageFooter />
                </div>
            )}
        </Spring>
    );
};

export default ContactForm;
