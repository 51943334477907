export default (state, action) => {
    switch (action.type) {
        case "CHANGE_LANG":
            if (action.payload === "none") {
                return { ...state, language: "English" };
            } else {
                return { ...state, language: action.payload };
            }
        default:
            return state;
    }
};
