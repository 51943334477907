import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./components/Pages/Home";
import { createBrowserHistory } from "history";
import HivContent from "./components/Pages/HivContent";
import Resources from "./components/Pages/Resources";
import Contact from "./components/Pages/Contact";
import "./App.css";

export const history = createBrowserHistory();

const App = () => {
    return (
        <div className='app_container'>
            <Router history={history}>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <ProtectedRoute
                        exact
                        path='/hiv-content'
                        component={HivContent}
                    />
                    <Route exact path='/contact' component={Contact} />
                    <ProtectedRoute
                        exact
                        path='/resources'
                        component={Resources}
                    />
                </Switch>
            </Router> 
        </div>
    );
};

export default App;
