import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import firestore from "../../logic/firestore";
import {
    english_names,
    isixhosa_names,
    sesotho_names,
    isizulu_names,
} from "../../video_content";
import languageContext from "../../context/language/languageContext";
import { MdCloudDownload } from "react-icons/md";
import "../styles/SectionHeader.css";

// Create analytics instance
const analytics = firestore.analytics();

const SectionHeader = ({ title, underline }) => {
    // Bring in context to change language of videos
    // in the download
    const LanguageContext = useContext(languageContext);
    const { language } = LanguageContext;

    // Decide the colour of the underline
    const under_color = underline === "black" ? "-black" : "";

    // Create links container array to be populated
    // Make sure that lower case context language lives in a variable
    // Create variable in which video names can be changed according to context language
    // Create array to be looped over to pull out sections
    const dload_links = [];
    const dload_language = language.toLowerCase();
    let video_names;
    const sections = ["first", "second", "third", "fourth", "fifth"];
    if (dload_language === "english") {
        video_names = english_names;
    } else if (dload_language === "isixhosa") {
        video_names = isixhosa_names;
    } else if (dload_language === "sesotho") {
        video_names = sesotho_names;
    } else if (dload_language === "isizulu") {
        video_names = isizulu_names;
    }


    // A function that gets all download links for the
    // videos in the language that has been selected.
    const getURLs = async () => {
        // Set up base refs
        const storageRef = firestore.storage().ref();
        const folderRef = storageRef.child(dload_language);
        // Set up changing refs and a variable to hold each new
        // url before it is pushed to the dload_links array
        let nestedRef;
        let videoRef;
        let url;
        if (title === "Why get an HIV test?") {
            for (let i = 0; i < sections.length; i++) {
                nestedRef = folderRef.child(sections[i]);
                if (sections[i] === "first") {
                    for (let j = 0; j < 2; j++) {
                        videoRef = nestedRef.child(video_names[j]);
                        url = await videoRef.getDownloadURL();
                        dload_links.push(url);
                    }
                } else if (sections[i] === "second") {
                    for (let j = 2; j < 4; j++) {
                        videoRef = nestedRef.child(video_names[j]);
                        url = await videoRef.getDownloadURL();
                        dload_links.push(url);
                    }
                } else if (sections[i] === "third") {
                    videoRef = nestedRef.child(video_names[4]);
                    url = await videoRef.getDownloadURL();
                    dload_links.push(url);
                } else if (sections[i] === "fourth") {
                    for (let j = 5; j < 7; j++) {
                        videoRef = nestedRef.child(video_names[j]);
                        url = await videoRef.getDownloadURL();
                        dload_links.push(url);
                    }
                } else if (sections[i] === "fifth") {
                    for (let j = 7; j < 9; j++) {
                        videoRef = nestedRef.child(video_names[j]);
                        url = await videoRef.getDownloadURL();
                        dload_links.push(url);
                    }
                }
            }
        }
    };

    const downloadHelper = async () => {
        // Log Donwload-All event
        analytics.logEvent("download_all", {
            download_language: language,
        });

        //Download all videos
        for (let n = 0; n < dload_links.length; n++) {
            setTimeout(() => {
                try {
                    let download = document.createElement("a");
                    download.href = dload_links[n];
                    download.setAttribute("download", "download");
                    download.click();
                } catch (error) {
                    console.log(error);
                }
            }, 2000 * n);
        }
    };
    // 
  

    // useEffect here to run getURLs function
    // only if title = "Why get an HIV test?"
    useEffect(() => {
        if (title === "Why get an HIV test?") {
            getURLs();
        }
    });

    return (
        <div
            className={
                title === "Why get an HIV test?"
                    ? "sh_header_container-first"
                    : "sh_header_container"
            }
        >
            {title === "Why get an HIV test?" ? (
                <>
                <div
                    className='sh_dload_container-top'
                    onClick={downloadHelper}
                >
                    <Link>
                    <MdCloudDownload
                        size={50}
                        color={"rgb(88, 21, 143)"}
                        style={{ cursor: "pointer" }}
                    />
                    </Link>
                    <p className='sh_dload_p'>DOWNLOAD ALL</p>
                   
                </div>
                 <div
                 className='sh_dload_container-top'
                 >
                  <Link to='/resources'>
                 <MdCloudDownload
                     size={50}
                     color={"rgb(88, 21, 143)"}
                     style={{ cursor: "pointer" }}
                 />
                  </Link>
                  <p className='sh_dload_p'>DOWNLOAD RESOURCES</p> 
             </div>
             </>
                
            ) : null}
            <h1
                className={
                    title === "Why get an HIV test?"
                        ? `sh_h1${under_color}-first`
                        : `sh_h1${under_color}`
                }
            >
                {title}
            </h1>
            {title === "Why get an HIV test?" ? (
                <>
                {/* <div className='sh_dload_container' onClick={downloadHelper}>
                    <Link>
                    <MdCloudDownload
                        size={50}
                        color={"rgb(88, 21, 143)"}
                        style={{ cursor: "pointer" }}
                    />
                      </Link>
                    <p className='sh_dload_p'>DOWNLOAD ALL</p>
                  
                    
                </div> */}
                 <div className='sh_dload_container'>
                 <Link to='/resources'>
                 <MdCloudDownload
                     size={50}
                     color={"rgb(88, 21, 143)"}
                     style={{ cursor: "pointer" }}
                 />
                  </Link>
                  <p className='sh_dload_p'>DOWNLOAD RESOURCES</p>
                
                
             </div>
             </>
            ) : null}
        </div>
    );
    

};

export default React.memo(SectionHeader);
