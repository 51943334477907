import React from "react";
import SectionHeader from "./SectionHeader";
import VideoContainer from "./VideoContainer";
import "../styles/Content.css";

const ContentSection = ({
    section,
    underline,
    header,
    description,
    content,
    urls,
}) => {
    // Applies different CSS styling according to 'section'
    // passed into component
    const section_class = `cntnt_container-${section}`;

    // Video rendering helper. Decides if there is one video
    // the section or two, and displays accordingly
    const videoRender = () => {
        if (Object.keys(urls).length === 2) {
            return (
                <VideoContainer
                    section={section}
                    s_left={urls.first}
                    t_left={content.first.title}
                    desc_left={content.first.description}
                    s_right={urls.second}
                    t_right={content.second.title}
                    desc_right={content.second.description}
                />
            );
        } else {
            return (
                <VideoContainer
                    section={section}
                    s_left={urls.first}
                    t_left={content.first.title}
                    desc_left={content.first.description}
                />
            );
        }
    };

    return (
        <div className={section_class}>
            <div className='cntnt_content'>
                <SectionHeader title={header} underline={underline} />
                {description ? <p className='cntnt_p'>{description}</p> : null}
                {videoRender()}
            </div>
        </div>
    );
};

export default React.memo(ContentSection);
