import firebase from "firebase/app";
import "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/analytics";
import "firebase/performance";

// Firebase configuration
var config = {
    apiKey: "AIzaSyDkxCXol_7aZ5OzfwSV0I-fyORLpChUGpA",
    authDomain: "hiv-engage-a7096.firebaseapp.com",
    databaseURL: "https://hiv-engage-a7096.firebaseio.com",
    projectId: "hiv-engage-a7096",
    storageBucket: "hiv-engage-a7096.appspot.com",
    messagingSenderId: "1015882821828",
    appId: "1:1015882821828:web:25fc4de0f3d78d006cd991",
    measurementId: "G-VJ52ELWCY0",
};
// Initialize Firebase
firebase.initializeApp(config);
firebase.analytics();
firebase.performance();

export default firebase;

// IGNORE BELOW //

// If needed in future, this function was used to change the content-type
// of the video's stored on firebase, as when videos are uploaded manually through
// the console there content-type does not allow automatic browser downloads when
// their download URLs are clicked

// const changeContent = async () => {
//     const baseRef = firestore.storage().ref();
//     const folderRef = baseRef.child("sesotho/fifth");
//     const videoRef = folderRef.child(
//         "HIV ENGAGE 8 - Everyday HIV Q&A_SOTHO_2.mp4"
//     );
//     const meta = await videoRef.getMetadata();
//     const metanew = meta.contentDisposition.replace(
//         "inline",
//         "attachment"
//     );
//     await videoRef.updateMetadata({
//         contentDisposition: metanew,
//     });
//     const check = await videoRef.getMetadata();
//     console.log(check.contentDisposition);
// };
// changeContent();
