import React, { useState, useContext, useEffect, Fragment } from "react";
import firestore from "../../logic/firestore";
import { anonSignIn } from "../../logic/userLogic";
import languageContext from "../../context/language/languageContext";
import { Spring } from "react-spring/renderprops";
import {
    headers,
    section_descriptions,
    urls,
    content,
} from "../../video_content";
import TopNavigation from "../UI/TopNavigation";
import LanguageToggle from "../UI/LanguageToggle";
import ContentSection from "../UI/ContentSection";
import Menu from "../UI/Menu";
import Banner from "../../assets/Banner.png";
import PageFooter from "../UI/PageFooter";
import "../styles/HivContent.css";

const HivContent = () => {
    // Instantiate burger state for menu toggle
    const [burger, setBurger] = useState(false);

    // Bring out language context for toggle
    const LanguageContext = useContext(languageContext);
    const { language } = LanguageContext;

    // Check if user is signed in or not
    useEffect(() => {
        const auth = firestore.auth();
        auth.onAuthStateChanged((user) => {
            if (!user) {
                // Sign user in
                anonSignIn();
            }
        });
    }, []);

    // Destructuring urls to pass into content components
    const { english, isiXhosa, seSotho, isiZulu } = urls;

    // Destructuring data to pass into content components
    const { first, second, third, fourth, fifth } = content;

    // Decide which language to display
    let currentLanguage;
    if (language === "English") {
        currentLanguage = english;
    } else if (language === "IsiXhosa") {
        currentLanguage = isiXhosa;
    } else if (language === "SeSotho") {
        currentLanguage = seSotho;
    } else if (language === "IsiZulu") {
        currentLanguage = isiZulu;
    }

    // HIV Content UI helper function
    const hivHelper = () => {
        return (
            <Spring
                from={{ opacity: "0", transform: "translateX(-500px)" }}
                to={{ opacity: "1", transform: "translateX(0px)" }}
            >
                {(props) => (
                    <div style={props}>
                        <div className='hiv_container'>
                            <div className='hiv_content_container'>
                                <h1 className='hiv_h1'>HIV Resources.</h1>
                                <p className='hiv_p'>
                                    We all have a story. With these videos, you
                                    get to learn from others so you can take
                                    care of your own health by knowing your HIV
                                    status.
                                </p>
                            </div>
                        </div>
                        <LanguageToggle />
                        <ContentSection
                            section='pre'
                            underline='black'
                            description={section_descriptions.first}
                            header={headers.first}
                            content={first}
                            urls={currentLanguage.first}
                        />
                        <ContentSection
                            section='during'
                            header={headers.second}
                            content={second}
                            urls={currentLanguage.second}
                        />
                        <ContentSection
                            section='post'
                            header={headers.third}
                            content={third}
                            urls={currentLanguage.third}
                        />
                        <ContentSection
                            section='positive'
                            header={headers.fourth}
                            content={fourth}
                            urls={currentLanguage.fourth}
                        />
                        <ContentSection
                            section='basic'
                            underline='black'
                            header={headers.fifth}
                            content={fifth}
                            urls={currentLanguage.fifth}
                        />
                        <img src={Banner} alt='Banner' className='hiv_banner' />
                        <PageFooter placement='hiv-content' />
                    </div>
                )}
            </Spring>
        );
    };

    // #################### Burger Menu Toggle #################### //

    if (!burger) {
        return (
            <Fragment>
                <TopNavigation
                    burger={burger}
                    menuTrigger={() => setBurger(!burger)}
                />
                {hivHelper()}
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <TopNavigation
                    burger={burger}
                    menuTrigger={() => setBurger(!burger)}
                />
                <Menu />
            </Fragment>
        );
    }
};

export default HivContent;
