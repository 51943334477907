import React from "react";
import { Route } from "react-router-dom";
import { history } from "../App";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    // Built to wrap HIV-Content route. Each time a user navigates
    // to the route, client side browser is checked for "engage_token"
    return (
        <Route
            {...rest}
            render={(props) => {
                if (localStorage.getItem("engage_token")) {
                    return <Component {...props} />;
                } else {
                    // If token does not exist within the clients browser
                    // the user is pushed back to the home page where they
                    // will need to fill out either one of the forms
                    history.push("/");
                }
            }}
        />
    );
};

export default ProtectedRoute;
