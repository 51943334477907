import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// Language context imported below. Context files were structured
// similarly to that of a Redux store
import LanguageState from "./context/language/LanguageState";

ReactDOM.render(
    <LanguageState>
        <App />
    </LanguageState>,
    document.querySelector("#root")
);
