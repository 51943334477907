// All video content information needed throughout
// the web application is stored within this file

// Video Section Headers
export const headers = {
    first: "Why get an HIV test?",
    second: "HIV basics - what you need to know",
    third: "My HIV test result is negative",
    fourth: "My HIV test result is positive",
    fifth: "Living with HIV - what do you need to know",
};

// Section desciptions
export const section_descriptions = {
    first:
        "Many people do not think they are at risk of getting HIV. Watch the videos below to learn about difference experiences and why getting tested is important for everyone.",
};

// Video URLs
export const urls = {
    english: {
        first: {
            first: "https://player.vimeo.com/video/601660951",
            second: "https://player.vimeo.com/video/601661294",
        },
        second: {
            first: "https://player.vimeo.com/video/601661656",
            second: "https://player.vimeo.com/video/601663213",
        },
        third: {
            first: "https://player.vimeo.com/video/601661794",
        },
        fourth: {
            first: "https://player.vimeo.com/video/601662041",
            second: "https://player.vimeo.com/video/601662365",
        },
        fifth: {
            first: "https://player.vimeo.com/video/601662486",
            second: "https://player.vimeo.com/video/601662986",
        },
    },
    isiXhosa: {
        first: {
            first: "https://player.vimeo.com/video/610467019",
            second: "https://player.vimeo.com/video/610467619",
        },
        second: {
            first: "https://player.vimeo.com/video/610467827",
            second: "https://player.vimeo.com/video/610471865",
            
        },
        third: {
            first: "https://player.vimeo.com/video/610468335",
        },
        fourth: {
            first: "https://player.vimeo.com/video/610469003",
            second: "https://player.vimeo.com/video/610469631",
        },
        fifth: {
            first: "https://player.vimeo.com/video/610470226",
            second: "https://player.vimeo.com/video/610470857",
        },
    },
    seSotho: {
        first: {
            first: "https://player.vimeo.com/video/610463390",
            second: "https://player.vimeo.com/video/610464133",
        },
        second: {
            first: "https://player.vimeo.com/video/610464629",
            second: "https://player.vimeo.com/video/610466650"
        },
        third: {
            first: "https://player.vimeo.com/video/610465164",
        },
        fourth: {
            first: "https://player.vimeo.com/video/610465379",
            second: "https://player.vimeo.com/video/610465850",
        },
        fifth: {
            first: "https://player.vimeo.com/video/610465953",
            second: "https://player.vimeo.com/video/610466451",
        },
    },
    isiZulu: {
        first: {
            first: "https://player.vimeo.com/video/610472506",
            second: "https://player.vimeo.com/video/610473335",
        },
        second: {
            first: "https://player.vimeo.com/video/610473950",
            second: "https://player.vimeo.com/video/610479637"
        },
        third: {
            first: "https://player.vimeo.com/video/610475200",
        },
        fourth: {
            first: "https://player.vimeo.com/video/610475990",
            second: "https://player.vimeo.com/video/610477022",
        },
        fifth: {
            first: "https://player.vimeo.com/video/610477955",
            second: "https://player.vimeo.com/video/610478943",
        },
    },
};

// Video title and content
export const content = {
    first: {
        first: {
            title: "My HIV Test",
            description:
                "Here we meet Lizette, Thabo, Valencia and the Khumalos. They all take us through their respective journeys and relationships. They let us in on their possible risks of getting HIV and why they decided to get tested.",
        },
        second: {
            title: "What is HIV?",
            description:
                "On this video, Thabo and Valencia share with us important basic information on HIV and how it affects our bodies when we are infected. This is important for us to know so we can take the necessary steps should a test come back with a positive results.",
        },
    },
    second: {
        first: {
            title: "How is HIV Spread?",
            description:
                "Mr Khumalo and Valencia debunk myths and uncertainities by giving us facts on how HIV is transmitted. This is useful information when mapping what risks of exposure we could have encountered in the last 3 months.",
        },
        second: {
            title: "Understanding COVID-19",
            description:
                "COVID-19 has changed the way we live our daily lives. This video shows 6 clear interventions to stop the spread of Covid-19, so you can protect yourself and your community.",
        }
    },
    third: {
        first: {
            title: "Staying Negative",
            description:
                "Lizette continues to take us through her journey as a partner to Donnie, and how she is taking care of herself to ensure that she stays HIV negative while she is figuring out other aspects of her life and marriage.",
        },
    },
    fourth: {
        first: {
            title: "Starting my ARV Treatment",
            description:
                "Valencia and Thabo take us through their journey on taking ARV treatment. Mr Khumalo also lets us in on how he is supporting his wife in taking her ARVs while he is taking his PrEP to protect each other and their beautiful family.",
        },
        second: {
            title: "Understanding ARVs",
            description:
                "Mr Khumalo and Lizette explain to us how ARVs work and how they should be take n to ensure that you stay healthy and decrease the chances of even passing it onto the next person. Through this, they make us better understand U=U and what it means.",
        },
    },
    fifth: {
        first: {
            title: "Living with HIV and Dealing with Stigma",
            description:
                "Thabo lets us in on his experience with starting ARV treatment and stopping because he was doing well. His journey is an important lesson on staying adherent and consistently looking after your health and taking care of yourself.",
        },
        second: {
            title: "Everyday HIV Q&A",
            description:
                "Dr Sindi answers questions about HIV stigma and discrimination.",
        },
    },
};

// Firebase video names per language (for download all)

export const english_names = [
    "HIV ENGAGE 1 - My HIV Test.mp4",
    "HIV ENGAGE 2 - What is HIV.mp4",
    "HIV ENGAGE 3 - How is HIV Spread.mp4",
    "HIV ENGAGE 9 - Understanding COVID-19.mp4",
    "HIV ENGAGE 4 - Staying HIV negative.mp4",
    "HIV ENGAGE 5 - Starting my ARV treatment.mp4",
    "HIV ENGAGE 6 - Understanding ARV’s.mp4",
    "HIV ENGAGE 7 - Living with HIV.mp4",
    "HIV ENGAGE 8 - Everyday HIV Q&A.mp4",
];

export const isixhosa_names = [
    "HIV ENGAGE 1 - My HIV test_XHOSA_2.mp4",
    "HIV ENGAGE 2 - What is HIV_XHOSA_2.mp4",
    "HIV ENGAGE 3 - How is HIV Spread_XHOSA_2.mp4",
    "HIV ENGAGE 9 - Understanding COVID-19_XHOSA.mp4",
    "HIV ENGAGE 4 - Staying HIV negative_XHOSA_2.mp4",
    "HIV ENGAGE 5 - Starting my ARV treatment_XHOSA_2.mp4",
    "HIV ENGAGE 6 - Understanding ARV’s_XHOSA_2.mp4",
    "HIV ENGAGE 7 - Living with HIV_XHOSA_2.mp4",
    "HIV ENGAGE 8 - Everyday HIV Q&A_XHOSA_2.mp4"
];

export const sesotho_names = [
    "HIV ENGAGE 1 - My HIV test_SOTHO_2.mp4",
    "HIV ENGAGE 2 - What is HIV_SOTHO_2.mp4",
    "HIV ENGAGE 3 - How is HIV Spread_SOTHO_2.mp4",
    "HIV ENGAGE 9 - Understanding COVID-19_SOTHO.mp4",
    "HIV ENGAGE 4 - Staying HIV negative_SOTHO_2.mp4",
    "HIV ENGAGE 5 - Starting my ARV treatment_SOTHO_2.mp4",
    "HIV ENGAGE 6 - Understanding ARV’s_SOTHO_2.mp4",
    "HIV ENGAGE 7 - Living with HIV_SOTHO_2.mp4",
    "HIV ENGAGE 8 - Everyday HIV Q&A_SOTHO_2.mp4",
];

export const isizulu_names = [
    "HIV ENGAGE 1 - My HIV test_ZULU_1.mp4",
    "HIV ENGAGE 2 - What is HIV_ZULU_1.mp4",
    "HIV ENGAGE 3 - How is HIV Spread_ZULU_1.mp4",
    "HIV ENGAGE 9 - Understanding COVID-19_ZULU.mp4",
    "HIV ENGAGE 4 - Staying HIV negative_ZULU_1.mp4",
    "HIV ENGAGE 5 - Starting my ARV treatment_ZULU_1.mp4",
    "HIV ENGAGE 6 - Understanding ARV’s_ZULU_1.mp4",
    "HIV ENGAGE 7 - Living with HIV_ZULU_1.mp4",
    "HIV ENGAGE 8 - Everyday HIV Q&A_ZULU_1.mp4",
];
