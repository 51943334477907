import React from "react";
import { Spring } from "react-spring/renderprops";
import { TiThMenu } from "react-icons/ti";
import "../styles/Burger.css";

const Burger = ({ from, to, menuTrigger }) => {
    return (
        <Spring
            config={{ duration: 300 }}
            from={{
                opacity: `${from === "0deg" ? "1" : "0.5"}`,
                transform: `rotate(${from})`,
            }}
            to={{
                opacity: `${from === "0deg" ? "0.5" : "1"}`,
                transform: `rotate(${to})`,
            }}
        >
            {(props) => (
                <div style={props}>
                    <TiThMenu
                        size={30}
                        className='nav_burger'
                        style={{ cursor: "pointer" }}
                        onClick={menuTrigger}
                    />
                </div>
            )}
        </Spring>
    );
};

export default Burger;
