import React, { useState, useEffect, Fragment } from "react";
import firestore from "../../logic/firestore";
import { anonSignIn } from "../../logic/userLogic";
import TopNavigation from "../UI/TopNavigation";
import Menu from "../UI/Menu";
import LandingContent from "../UI/LandingContent";
import HealthForm from "../forms/HealthForm";
import PublicForm from "../forms/PublicForm";
import { Spring } from "react-spring/renderprops";

const Home = () => {
    // Initialize state to help toggle forms
    // and for burger menu toggle
    const [formToggle, setFormToggle] = useState("Landing");
    const [burger, setBurger] = useState(false);

    // Sign user in anonymously so that firestore can
    // be interacted with for duration
    useEffect(() => {
        const auth = firestore.auth();
        auth.onAuthStateChanged((user) => {
            if (!user) {
                anonSignIn();
            }
        });
    }, []);

    // Form setter function to be passed down
    // to different forms
    const formSetter = (form) => {
        setFormToggle(form);
    };

    // Helper function to render different forms
    // based on value of formToggle
    const formRender = () => {
        if (formToggle === "Landing") {
            return (
                <Spring
                    from={{ opacity: "0", transform: "translateX(-500px)" }}
                    to={{ opacity: "1", transform: "translateX(0px)" }}
                >
                    {(props) => (
                        <div style={props}>
                            <LandingContent formSetter={formSetter} />
                        </div>
                    )}
                </Spring>
            );
        } else if (formToggle === "Health") {
            return <HealthForm formSetter={formSetter} />;
        } else if (formToggle === "Public") {
            return <PublicForm formSetter={formSetter} />;
        } else {
            // Defensive: Just incase somehow the value
            // doesn't match one of the above
            setFormToggle("Landing");
        }
    };

    // #################### Burger Menu Toggle #################### //

    if (!burger) {
        return (
            <Fragment>
                <TopNavigation
                    formSetter={formSetter}
                    burger={burger}
                    menuTrigger={() => setBurger(!burger)}
                />
                {formRender()}
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <TopNavigation
                    formSetter={formSetter}
                    burger={burger}
                    menuTrigger={() => setBurger(!burger)}
                />
                <Menu />
            </Fragment>
        );
    }
};

export default Home;
