import React, { useState, useContext } from "react";
import languageContext from "../../context/language/languageContext";
import { Spring } from "react-spring/renderprops";
import { setCurrentUser } from "../../logic/userLogic";
import Loading from "../UI/Loading";
import PageFooter from "../UI/PageFooter";
import "../styles/PublicForm.css";

const PublicForm = ({ formSetter }) => {
    // Bring in context so that language (context)
    // can be change when user selects a language
    const LanguageContext = useContext(languageContext);
    const { changeLanguage } = LanguageContext;

    // Initialize state to be used in form
    const [language, setLanguage] = useState("");
    const [gender, setGender] = useState("");
    const [otherSpecified, setOtherSpecified] = useState("");
    const [age, setAge] = useState("");
    const [policy, setPolicy] = useState(false);
    const [policyError, setPolicyError] = useState(false);
    if (policyError && policy) {
        setPolicyError(false);
    }
    // State for gender error
    const [genderError, setGenderError] = useState(false);
    if (
        (genderError && otherSpecified !== "") ||
        (genderError && gender !== "Other")
    ) {
        setGenderError(false);
    }
    // State to show spinner during submission
    const [submitted, setSubmitted] = useState(false);

    // Continue button helper function
    const submitHelper = () => {
        // Create user object to be sent to
        // firestore
        let data;
        if (policy) {
            if (gender === "Other" && otherSpecified === "") {
                setGenderError(true);
            } else {
                if (gender === "Other") {
                    data = {
                        language,
                        gender,
                        otherSpecified,
                        age,
                    };
                } else {
                    data = {
                        language,
                        gender,
                        age,
                    };
                }
                // Show spinner
                setSubmitted(true);
                // Set userHealth through context function
                setCurrentUser("Public", data);
            }
        } else {
            setPolicyError(true);
        }
    };

    // Privacy Policy toggle
    // Change css based on state of "policy" state
    const yes_policy = policy ? "-selected" : "";
    const no_policy = !policy ? "-selected" : "";
    const yes_p = policy ? "-selected" : "";
    const no_p = !policy ? "-selected" : "";

    if (!submitted) {
        return (
            <Spring
                from={{ opacity: "0", transform: "translateX(500px)" }}
                to={{ opacity: "1", transform: "translateX(0px)" }}
            >
                {(props) => (
                    <div className='pf_container' style={props}>
                        <div className='pf_form_button_container'>
                            <div className='pf_content_container'>
                                <h2 className='pf_h2'>
                                    Tell us a bit about yourself:
                                </h2>
                                <div className='pf_inputs_1'>
                                    <select
                                        value={language}
                                        onChange={(e) => {
                                            setLanguage(e.target.value);
                                            changeLanguage(e.target.value);
                                        }}
                                        className='pf_personal_info_inputs'
                                    >
                                        <option
                                            value='none'
                                            selection='selected'
                                        >
                                            Language
                                        </option>
                                        <option value='English'>English</option>
                                        <option value='IsiXhosa'>
                                            IsiXhosa
                                        </option>
                                        <option value='SeSotho'>SeSotho</option>
                                        <option value='IsiZulu'>IsiZulu</option>
                                    </select>
                                    <select
                                        value={gender}
                                        onChange={(e) =>
                                            setGender(e.target.value)
                                        }
                                        className='pf_personal_info_inputs'
                                    >
                                        <option
                                            value='none'
                                            selection='selected'
                                        >
                                            Gender
                                        </option>
                                        <option value='Woman'>Woman</option>
                                        <option value='Male'>Man</option>
                                        <option value='Genderqueer/Non-binary'>
                                            Genderqueer/Non-binary
                                        </option>
                                        <option value='Other'>
                                            Other (please specify)
                                        </option>
                                        <option value='Prefer not to disclose'>
                                            Prefer not to disclose
                                        </option>
                                    </select>
                                </div>
                                <div className='pf_inputs_2'>
                                    {gender === "Other" ? (
                                        <Spring
                                            config={{ duration: 300 }}
                                            from={{
                                                transform: "translateX(-100px)",
                                                opacity: "0.5",
                                            }}
                                            to={{
                                                transform: "translateX(0px)",
                                                opacity: "1",
                                            }}
                                        >
                                            {(props) => (
                                                <div style={props}>
                                                    <input
                                                        className={`pf_personal_info_inputs_bottom-spec${
                                                            genderError
                                                                ? "-error"
                                                                : ""
                                                        }`}
                                                        type='text'
                                                        placeholder='Specify here please'
                                                        value={otherSpecified}
                                                        onChange={(e) =>
                                                            setOtherSpecified(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Spring>
                                    ) : null}
                                    {genderError ? (
                                        <Spring
                                            config={{ duration: 300 }}
                                            from={{
                                                transform: "translateX(-100px)",
                                                opacity: "0.5",
                                            }}
                                            to={{
                                                transform: "translateX(0px)",
                                                opacity: "1",
                                            }}
                                        >
                                            {(props) => (
                                                <p
                                                    className='pf_error'
                                                    style={props}
                                                >
                                                    Please specify above or
                                                    change gender selection
                                                </p>
                                            )}
                                        </Spring>
                                    ) : null}
                                    <select
                                        value={age}
                                        onChange={(e) => {
                                            setAge(e.target.value);
                                        }}
                                        className='pf_personal_info_inputs_bottom'
                                    >
                                        <option
                                            value='none'
                                            selection='selected'
                                        >
                                            Your Age
                                        </option>
                                        <option value='18 - 25'>18 - 25</option>
                                        <option value='26 - 35'>26 - 35</option>
                                        <option value='36 - 45'>36 - 45</option>
                                        <option value='46+'>46+</option>
                                    </select>
                                </div>
                                <div className='pf_policy_container'>
                                    <div
                                        className={`toggle_container${
                                            policyError ? "-error" : ""
                                        }`}
                                    >
                                        <div
                                            className={`toggle_left${yes_policy}`}
                                            onClick={() => setPolicy(true)}
                                        >
                                            <p className={`toggle_p${yes_p}`}>
                                                Yes
                                            </p>
                                        </div>
                                        <div
                                            className={`toggle_right${no_policy}`}
                                            onClick={() => setPolicy(false)}
                                        >
                                            <p className={`toggle_p${no_p}`}>
                                                No
                                            </p>
                                        </div>
                                    </div>
                                    <p className='pf_policy_p'>
                                        I agree to Aviro's{" "}
                                        <a
                                            href='http://www.avirohealth.com/privacy-policy/'
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            style={{
                                                textDecoration: "underline",
                                                color: "white",
                                            }}
                                        >
                                            Privacy Policy
                                        </a>
                                    </p>
                                </div>
                                <div className='pf_buttons_container'>
                                    <button
                                        className='pf_button'
                                        onClick={submitHelper}
                                    >
                                        CONTINUE
                                    </button>
                                    <button
                                        className='pf_button_skip'
                                        onClick={submitHelper}
                                    >
                                        SKIP
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p
                            className='pf_p'
                            onClick={() => {
                                formSetter("Landing");
                            }}
                        >
                            Are you a healthcare worker? Click here...
                        </p>
                        <PageFooter />
                    </div>
                )}
            </Spring>
        );
    } else {
        return <Loading />;
    }
};

export default PublicForm;
