import React from "react";
import facebook from "../../assets/facebook.svg";
import linkedin from "../../assets/linkedin.svg";
import twitter from "../../assets/twitter.svg";
import { AiOutlineCopyright } from "react-icons/ai";
import "../styles/PageFooter.css";

const PageFooter = ({ placement }) => {
    const background = placement === "hiv-content" ? "-hiv" : "";
    return (
        <div className={`ftr_container${background}`}>
            <div className='ftr_social'>
                <a
                    href='https://www.facebook.com/avirohealth/'
                    className='social_a'
                    rel='noopener noreferrer'
                    target='_blank'
                >
                    <img
                        src={facebook}
                        alt='facebook'
                        className='ftr_social_icon'
                    />
                </a>
                <a
                    href='https://www.linkedin.com/company/aviro-health'
                    className='social_a'
                    rel='noopener noreferrer'
                    target='_blank'
                >
                    <img
                        src={linkedin}
                        alt='linkedin'
                        className='ftr_social_icon'
                    />
                </a>
                <a
                    href='https://twitter.com/AviroHealth'
                    className='social_a'
                    rel='noopener noreferrer'
                    target='_blank'
                >
                    <img
                        src={twitter}
                        alt='twitter'
                        className='ftr_social_icon'
                    />
                </a>
            </div>
            <div className='ftr_copyright_container'>
                <AiOutlineCopyright size={12} />
                <p className='ftr_copyright_p'>
                    Copyright 2021 | Aviro Health | All Rights Reserved
                </p>
            </div>
        </div>
    );
};

export default PageFooter;
