import React, { useState, useContext } from "react";
import languageContext from "../../context/language/languageContext";
import { Spring } from "react-spring/renderprops";
import Language_Icon from "../../assets/language_icon.png";
import firestore from "../../logic/firestore";
import "../styles/LanguageToggle.css";

// Create analytics instance
const analytics = firestore.analytics();

const LanguageToggle = () => {
    // State for menu toggle (show menu)
    const [expanded, setExpanded] = useState(false);

    // Extraction of from/to spring logic
    const from = !expanded ? "1.2" : "1";
    const to = !expanded ? "1" : "1.2";
    const opacity_from = !expanded ? "0.8" : "1";
    const opacity_to = !expanded ? "1" : "0.8";

    // Change container size based 'expanded' state
    const size = !expanded ? "" : "-expanded";

    // Get access to languageContext and pull
    // out changeLanguage
    const LanguageContext = useContext(languageContext);
    const { changeLanguage } = LanguageContext;
    const languages = [
        { lang: "English" },
        { lang: "IsiXhosa" },
        { lang: "SeSotho" },
        { lang: "IsiZulu" },
    ];

    // Language render helper. Decides whether
    // to show language choices or not based
    // on 'expanded' state
    const languageRender = () => {
        if (!expanded) {
            return <p className='lt_p'>Switch Video Language</p>;
        } else {
            return (
                <div className='lt_languages'>
                    {languages.map((language) => {
                        return (
                            <Spring
                                config={{ duration: 300 }}
                                from={{
                                    transform: "translateY(-30px)",
                                    opacity: "0.5",
                                }}
                                to={{
                                    transform: "translateY(0px)",
                                    opacity: "1",
                                }}
                                key={language.lang}
                            >
                                {(props) => (
                                    <div style={props}>
                                        <p
                                            className='lt_p-choice'
                                            onClick={() => {
                                                setExpanded(false);
                                                changeLanguage(language.lang);
                                                // Log Language-Change event
                                                analytics.logEvent(
                                                    "language_changed",
                                                    {
                                                        to: language.lang,
                                                    }
                                                );
                                            }}
                                        >
                                            {language.lang}
                                        </p>
                                    </div>
                                )}
                            </Spring>
                        );
                    })}
                </div>
            );
        }
    };

    return (
        <div className={`lt_container${size}`}>
            <Spring
                config={{ duration: 300 }}
                from={{ transform: `scale(${from})`, opacity: opacity_from }}
                to={{ transform: `scale(${to})`, opacity: opacity_to }}
            >
                {(props) => (
                    <div style={props}>
                        <img
                            src={Language_Icon}
                            alt='toggle_icon'
                            style={{ cursor: "pointer" }}
                            onClick={() => setExpanded(!expanded)}
                        />
                    </div>
                )}
            </Spring>
            {languageRender()}
        </div>
    );
};

export default LanguageToggle;
