import React from "react";
import GridLoader from "react-spinners/GridLoader";

const Loading = () => {
    return (
        <div
            style={{
                height: "calc(100vh - 80px)",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <GridLoader size={20} color={"#663873"} />
        </div>
    );
};

export default Loading;
