import React, { useState, useContext, useEffect } from "react";
import { MdCloudDownload } from "react-icons/md";
import languageContext from "../../context/language/languageContext";
import {
    english_names,
    isixhosa_names,
    sesotho_names,
    isizulu_names,
} from "../../video_content";
import firestore from "../../logic/firestore";
import "../styles/VideoContainer.css";

// Create analytics instance
const analytics = firestore.analytics();

const VideoContainer = ({
    section,
    t_left,
    t_right,
    desc_left,
    desc_right,
    s_left,
    s_right,
}) => {
    // State for donwload links
    const [dlinkLeft, setDLinkLeft] = useState("");
    const [dlinkRight, setDLinkRight] = useState("");

    // Changes CSS to accomodate for number of
    // videos per section
    const is_single = s_right ? "-right" : "-novideo";
    const exists = s_right ? "-exists" : "";

    // Bring in Language context so that new download
    // urls can be fetched if language changes
    const LanguageContext = useContext(languageContext);
    const { language } = LanguageContext;

    // Get video names in chosen language
    const dload_language = language.toLowerCase();
    let video_names;
    if (dload_language === "english") {
        video_names = english_names;
    } else if (dload_language === "isixhosa") {
        video_names = isixhosa_names;
    } else if (dload_language === "sesotho") {
        video_names = sesotho_names;
    } else if (dload_language === "isizulu") {
        video_names = isizulu_names;
    }

    useEffect(() => {
        const getURLs = async () => {
            // Set up base refs
            const storageRef = firestore.storage().ref();
            const folderRef = storageRef.child(dload_language);
            // Set up changing refs and a variable to hold each new
            // url before it is pushed to the dload_links array
            let nestedRef;
            let videoRef;
            let url;
            if (section === "pre") {
                nestedRef = folderRef.child("first");
                for (let i = 0; i < 2; i++) {
                    videoRef = nestedRef.child(video_names[i]);
                    url = await videoRef.getDownloadURL();
                    if (i === 0) {
                        setDLinkLeft(url);
                    } else if (i === 1) {
                        setDLinkRight(url);
                    }
                }
            } else if (section === "during") {
                nestedRef = folderRef.child("second");
                for (let i = 2; i < 4; i++) {
                    videoRef = nestedRef.child(video_names[i]);
                    url = await videoRef.getDownloadURL();
                    if (i === 2) {
                        setDLinkLeft(url);
                    } else if (i === 3) {
                        setDLinkRight(url);
                    }
                }
            } else if (section === "post") {
                nestedRef = folderRef.child("third");
                videoRef = nestedRef.child(video_names[4]);
                url = await videoRef.getDownloadURL();
                setDLinkLeft(url);
            } else if (section === "positive") {
                nestedRef = folderRef.child("fourth");
                for (let i = 5; i < 7; i++) {
                    videoRef = nestedRef.child(video_names[i]);
                    url = await videoRef.getDownloadURL();
                    if (i === 5) {
                        setDLinkLeft(url);
                    } else if (i === 6) {
                        setDLinkRight(url);
                    }
                }
            } else if (section === "basic") {
                nestedRef = folderRef.child("fifth");
                for (let i = 7; i < 9; i++) {
                    videoRef = nestedRef.child(video_names[i]);
                    url = await videoRef.getDownloadURL();
                    if (i === 7) {
                        setDLinkLeft(url);
                    } else if (i === 8) {
                        setDLinkRight(url);
                    }
                }
            }
        };
        getURLs();
    }, [dload_language, section, video_names]);

    return (
        <div className='videos_container'>
            <div className='video_content'>
                <div className='iframe_container'>
                    <iframe
                        className='video'
                        title='title'
                        src={s_left}
                        frameBorder='0'
                        allow='autoplay; fullscreen'
                        allowFullScreen
                    ></iframe>
                </div>
                <div className='description_container'>
                    <div className='description_content'>
                        <h1 className='video_header'>{t_left}</h1>
                        <p className='video_description'>{desc_left}</p>
                    </div>
                    
                    {/*<div className='download_container'>
                        <a
                            href={dlinkLeft}
                            download
                            onClick={() =>
                                // Log Video-Downloaded event
                                analytics.logEvent("video_downloaded", {
                                    title: t_left,
                                    download_language: language,
                                })
                            }
                        >
                            <MdCloudDownload
                                size={50}
                                color={"rgb(88, 21, 143)"}
                                style={{ cursor: "pointer" }}
                            />
                        </a>
                        </div>*/}
                </div>
            </div>
            <div className={`video_content${is_single}`}>
                <div className={`iframe_container${exists}`}>
                    <iframe
                        className='video'
                        title='title'
                        src={s_right}
                        frameBorder='0'
                        allow='autoplay; fullscreen'
                        allowFullScreen
                    ></iframe>
                </div>
                <div className='description_container'>
                    <div className='description_content'>
                        <h1 className='video_header'>{t_right}</h1>
                        <p className='video_description'>{desc_right}</p>
                    </div>

                    {/*<div className='download_container'>
                        <a
                            href={dlinkRight}
                            download
                            onClick={() =>
                                // Log Video-Downloaded event
                                analytics.logEvent("video_downloaded", {
                                    title: t_right,
                                    download_language: language,
                                })
                            }
                        >
                            <MdCloudDownload
                                className={`icon${is_single}`}
                                size={50}
                                color={"rgb(88, 21, 143)"}
                                style={{ cursor: "pointer" }}
                            />
                        </a>
                    </div>*/}
                    
                </div>
            </div>
        </div>
    );
};

export default VideoContainer;
