import React, { useReducer } from "react";
import languageContext from "./languageContext";
import languageReducer from "./languageReducer";

const LanguageState = (props) => {
    // Default language should be english
    const initialState = {
        language: "English",
    };

    // Bring in / connect to languageReducer
    const [state, dispatch] = useReducer(languageReducer, initialState);

    // State manipulation function that will be used by components
    // to change the language
    const changeLanguage = (lang) => {
        dispatch({ type: "CHANGE_LANG", payload: lang });
    };

    return (
        <languageContext.Provider
            value={{ language: state.language, changeLanguage }}
        >
            {props.children}
        </languageContext.Provider>
    );
};

export default LanguageState;
