import React, { useState, useContext } from "react";
import languageContext from "../../context/language/languageContext";
import { Spring } from "react-spring/renderprops";
import { setCurrentUser } from "../../logic/userLogic";
import Loading from "../UI/Loading";
import PageFooter from "../UI/PageFooter";
import "../styles/HealthForm.css";

const HealthForm = ({ formSetter }) => {
    // Bring in context so that language (context)
    // can be change when user selects a language
    const LanguageContext = useContext(languageContext);
    const { changeLanguage } = LanguageContext;

    // Initialize state to be used in form
    const [name, setName] = useState("");
    const [designation, setDesignation] = useState("");
    const [organization, setOrganization] = useState("");
    const [email, setEmail] = useState("");
    const [language, setLanguage] = useState("");
    const [contentUse, setContentUse] = useState("");
    const [marketing, setMarketing] = useState("Yes");
    const [policy, setPolicy] = useState(false);
    const [policyError, setPolicyError] = useState(false);
    if (policyError && policy) {
        setPolicyError(false);
    }
    // State to show error for email
    const [emailError, setEmailError] = useState(false);
    if ((emailError && email !== "") || (emailError && marketing === "No")) {
        setEmailError(false);
    }
    // State to show spinner during submission
    const [submitted, setSubmitted] = useState(false);

    // Continue button helper function
    const submitHelper = () => {
        // Set email error
        if (marketing === "Yes" && email === "") {
            setEmailError(true);
        } else {
            if (policy) {
                // Create user object to be sent to
                // firestore
                const data = {
                    name,
                    designation,
                    organization,
                    email,
                    language,
                    contentUse,
                    policy,
                    marketing,
                };
                // Show spinner
                setSubmitted(true);
                // Set userHealth through context function
                setCurrentUser("Health", data);
            } else {
                setPolicyError(true);
            }
        }
    };

    // Privacy Policy toggle
    // Change css based on state of "policy" state
    const yes_policy = policy ? "-selected" : "";
    const no_policy = !policy ? "-selected" : "";
    const yes_pol = policy ? "-selected" : "";
    const no_pol = !policy ? "-selected" : "";

    // Marketing  toggle
    // Change css based on state of marketing
    const yes_marketing = marketing === "Yes" ? "-selected" : "";
    const no_marketing = marketing === "No" ? "-selected" : "";
    const yes_p = marketing === "Yes" ? "-selected" : "";
    const no_p = marketing === "No" ? "-selected" : "";

    if (!submitted) {
        return (
            <Spring
                from={{ opacity: "0", transform: "translateX(500px)" }}
                to={{ opacity: "1", transform: "translateX(0px)" }}
            >
                {(props) => (
                    <div className='hf_container' style={props}>
                        <div className='hf_form_button_container'>
                            <div className='hf_content_container'>
                                <h2 className='hf_h2'>
                                    This content was made in partnership with
                                    the Department of Health so that Health Care
                                    providers could use this in their various
                                    platforms. By giving us this information,
                                    you are helping us keep track of health
                                    partners that are accessing the content.
                                    Kindly provide your details on the form
                                    below:
                                </h2>
                                <div className='hf_inputs_0'>
                                    <input
                                        className='hf_personal_info_inputs-full'
                                        type='text'
                                        value={name}
                                        placeholder='Name'
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                </div>
                                <div className='hf_inputs_1'>
                                    <input
                                        className='hf_personal_info_inputs'
                                        type='text'
                                        value={designation}
                                        placeholder='Designation'
                                        onChange={(e) =>
                                            setDesignation(e.target.value)
                                        }
                                    />
                                    <input
                                        className='hf_personal_info_inputs'
                                        type='text'
                                        value={organization}
                                        placeholder='Organization'
                                        onChange={(e) =>
                                            setOrganization(e.target.value)
                                        }
                                    />
                                </div>
                                <div className='hf_inputs_2'>
                                    <input
                                        className={`hf_personal_info_inputs${
                                            emailError ? "-error" : ""
                                        }`}
                                        type='text'
                                        value={email}
                                        placeholder='Email'
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                    <select
                                        value={language}
                                        onChange={(e) => {
                                            setLanguage(e.target.value);
                                            changeLanguage(e.target.value);
                                        }}
                                        className='hf_personal_info_inputs-lang'
                                    >
                                        <option
                                            value='none'
                                            selection='selected'
                                        >
                                            Language
                                        </option>
                                        <option value='English'>English</option>
                                        <option value='IsiXhosa'>
                                            IsiXhosa
                                        </option>
                                        <option value='SeSotho'>SeSotho</option>
                                        <option value='IsiZulu'>IsiZulu</option>
                                    </select>
                                </div>
                                <div>
                                    {emailError ? (
                                        <Spring
                                            config={{ duration: 300 }}
                                            from={{
                                                transform: "translateX(-40px)",
                                            }}
                                            to={{
                                                transform: "translateX(0px)",
                                            }}
                                        >
                                            {(props) => (
                                                <p
                                                    className='hf_error_p'
                                                    style={props}
                                                >
                                                    Please provide your email or
                                                    deselect our marketing
                                                    option
                                                </p>
                                            )}
                                        </Spring>
                                    ) : null}
                                </div>
                                <div className='hf_inputs_3'>
                                    <input
                                        className='hf_personal_info_inputs-contentuse'
                                        type='text'
                                        value={contentUse}
                                        placeholder='How will you use this content?'
                                        onChange={(e) =>
                                            setContentUse(e.target.value)
                                        }
                                    />
                                    <div className='hf_policy_container'>
                                        <div
                                            className={`hf_poltoggle_container${
                                                policyError ? "-error" : ""
                                            }`}
                                        >
                                            <div
                                                className={`toggle_left${yes_policy}`}
                                                onClick={() => setPolicy(true)}
                                            >
                                                <p
                                                    className={`toggle_p${yes_pol}`}
                                                >
                                                    Yes
                                                </p>
                                            </div>
                                            <div
                                                className={`toggle_right${no_policy}`}
                                                onClick={() => setPolicy(false)}
                                            >
                                                <p
                                                    className={`toggle_p${no_pol}`}
                                                >
                                                    No
                                                </p>
                                            </div>
                                        </div>
                                        <p className='hf_policy_p'>
                                            I agree to Aviro's{" "}
                                            <a
                                                href='http://www.avirohealth.com/privacy-policy/'
                                                rel='noopener noreferrer'
                                                target='_blank'
                                                style={{
                                                    textDecoration: "underline",
                                                    color: "white",
                                                }}
                                            >
                                                Privacy Policy
                                            </a>
                                        </p>
                                    </div>
                                    <div className='hf_marketing_container'>
                                        <div className='hf_marktoggle_container'>
                                            <div
                                                className={`toggle_left${yes_marketing}`}
                                                onClick={() =>
                                                    setMarketing("Yes")
                                                }
                                            >
                                                <p
                                                    className={`toggle_p${yes_p}`}
                                                >
                                                    Yes
                                                </p>
                                            </div>
                                            <div
                                                className={`toggle_right${no_marketing}`}
                                                onClick={() =>
                                                    setMarketing("No")
                                                }
                                            >
                                                <p
                                                    className={`toggle_p${no_p}`}
                                                >
                                                    No
                                                </p>
                                            </div>
                                        </div>
                                        <p className='hf_marketing_p'>
                                            I agree to Aviro sending me
                                            marketing material and related news.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <button
                                className='hf_button'
                                onClick={submitHelper}
                            >
                                CONTINUE
                            </button>
                        </div>
                        <p
                            className='hf_p'
                            onClick={() => {
                                formSetter("Landing");
                            }}
                        >
                            Not a Healthcare Professional? Click here...
                        </p>
                        <PageFooter />
                    </div>
                )}
            </Spring>
        );
    } else {
        return <Loading />;
    }
};

export default HealthForm;
