import React, { useState, useEffect, Fragment } from "react";
import firestore from "../../logic/firestore";
import { anonSignIn, storage } from "../../logic/userLogic";
import TopNavigation from "../UI/TopNavigation";
import Menu from "../UI/Menu";
import PageFooter from "../UI/PageFooter";
import Banner from "../../assets/Banner.png";
import "../styles/Resources.css";
import { MdCloudDownload } from "react-icons/md";
import { Spring } from "react-spring/renderprops";


// Create analytics instance
const analytics = firestore.analytics();

const Resources = () => {
  const posterDlink = []
  let flyerDlink;
  let contentOutlineDlink ;
// Instantiate burger state for menu toggle
    const [burger, setBurger] = useState(false);
   
// Check if user is signed in or not
    useEffect(() => {
        const auth = firestore.auth();
        auth.onAuthStateChanged((user) => {
            if (!user) {
                // Sign user in
                anonSignIn();
            } 
        });
    }, []);

    const fetchPosters= ()=>{
        let listRef = storage.ref().child('posters');
        // Create file metadata to update
        listRef.listAll().then((res) => {
            res.items.forEach(async (itemRef) => {
                itemRef.getDownloadURL()
                .then(url => {
                   posterDlink.push(url);
                })
                .catch(e=>{console.log(e);})         
            });
        }).catch((error) =>{console.log(error)});
        
    }
    const fetchFlyer= ()=>{
        var listRef = storage.ref().child('resources/Flyer.pdf');
       listRef.getDownloadURL()
       .then(url => {flyerDlink =url})
       .catch(e=>{console.log(e);})
        
    }
    const fetchContentOutline= ()=>{
        var listRef = storage.ref().child('resources/ContentOutline.pdf');
        listRef.getDownloadURL()
        .then(url => {contentOutlineDlink =url})
        .catch(e=>{console.log(e);})
         
     }
    useEffect(() => {
        fetchPosters();
        fetchFlyer();
        fetchContentOutline();
        
    });
    const downloadHelper = ()=>{
         // Log Download-posters event
         analytics.logEvent("download_posters", {
            name: "poster",
        });
        for (let n = 0; n < posterDlink.length; n++) {
            setTimeout(() => {
                try {
                    let download = document.createElement("a");
                    download.href = posterDlink[n];
                    download.setAttribute("download",'download');
                    download.click();
                } catch (error) {
                    console.log(error);
                }
            }, 2000 * n);
        }
    }
    const downloadFlyer = ()=>{
         // Log Download-Flyer event
         analytics.logEvent("download_flyer", {
            name: "flyer",
        });
            setTimeout(() => {
                try {
                    let download = document.createElement("a");
                    download.href = flyerDlink ;
                    download.setAttribute("download", "download");
                    download.click();
                } catch (error) {
                    console.log(error);
                }
            }, 1000);
        }
    const downloadContentOutline = ()=>{
         // Log Download-Content event
         analytics.logEvent("download_content", {
            name: "content_outline",
        });
            setTimeout(() => {
                try {
                    let download = document.createElement("a");
                    download.href = contentOutlineDlink ;
                    download.setAttribute("download", "download");
                    download.click();
                } catch (error) {
                    console.log(error);
                }
            }, 1000);
        }
    
    // Poster UI helper function
    const resources = () => {
         return (

            <Spring
            from={{ opacity: "0", transform: "translateX(-500px)" }}
            to={{ opacity: "1", transform: "translateX(0px)" }}
        >
            {(props) => (
                <div style={props}>
                    <div className='res_container'>
                        <div className='res_content_container'>
                            <h1 className='res_h1'>Other Resources</h1>
                            <p className='res_p'>
                                You can find all printable HIV Engage resources here.
                            </p>
                        </div>    
                    </div>
                    <div className="row offset-1">        
                        <div className="column">
                            <div className="card-1">
                            <div
                            onClick={downloadHelper}      
                            >
                            <MdCloudDownload
                            size={50}
                            color={"rgb(88, 21, 143)"}
                            style={{ cursor: "pointer" }}
                            />
                            <p className='res_h3'>ALL POSTERS</p>   
                            </div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="card-2">
                            <div
                            onClick={downloadFlyer}      
                            >
                            <MdCloudDownload
                            size={50}
                            color={"rgb(88, 21, 143)"}
                            style={{ cursor: "pointer" }}
                            />
                            <p className='res_h3'>FLYER</p>   
                            </div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="card-3">
                            <div
                            onClick={downloadContentOutline}      
                            >
                            <MdCloudDownload
                            size={50}
                            color={"rgb(88, 21, 143)"}
                            style={{ cursor: "pointer" }}
                            />
                            <p className='res_h3'>CONTENT OUTLINE</p>   
                            </div>
                            </div>
                        </div>
                        
                    </div> 
                    <img src={Banner} alt='Banner' className='res_banner' />
                    <PageFooter placement='hiv-content' />
                </div>
            )}
        </Spring>   
           
        );
    };

    // #################### Burger Menu Toggle #################### //

    if (!burger) {
        return (
            <Fragment>
                <TopNavigation
                    burger={burger}
                    menuTrigger={() => setBurger(!burger)}
                />
                {resources()}
              
               
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <TopNavigation
                    burger={burger}
                    menuTrigger={() => setBurger(!burger)}
                />
                <Menu />
               
               
            </Fragment>
        );
    }
};

export default Resources;
