import React, { createRef, Fragment } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import Banner from "../../assets/Banner.png";
import PageFooter from "./PageFooter";
import "../styles/LandingContent.css";

const LandingContent = ({ formSetter }) => {
    // Create "About" and "Home" refs for scrolling
    const aboutRef = createRef();
    const homeRef = createRef();

    // Scroller function (About)
    const toAboutScroll = () => {
        if (aboutRef.current) {
            aboutRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    };

    // Scroller function (Home)
    const toHomeScroll = () => {
        if (homeRef.current) {
            homeRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    };

    return (
        <Fragment>
            <div className='lf_container' ref={homeRef}>
                <div className='lf_content_container'>
                    <h1 className='lf_h1'>Welcome to HIV Engage.</h1>
                    <p className='lf_p'>
                        HIV Engage is a digital counselling syllabus that
                        provides accurate and engaging information about HIV.
                        From testing to ART initiation.
                    </p>
                    <h2 className='lf_h2'>
                        Please continue by making a selection below:
                    </h2>
                    <div className='lf_btn_container'>
                        <button
                            className='health_btn'
                            onClick={() => formSetter("Health")}
                        >
                            HEALTH PARTNER
                        </button>
                        <button
                            className='public_btn'
                            onClick={() => formSetter("Public")}
                        >
                            WATCH VIDEOS
                        </button>
                    </div>
                </div>
                <IoIosArrowDropdown
                    size={50}
                    className='scroll_indicator'
                    style={{
                        marginBottom: "3.5%",
                        cursor: "pointer",
                    }}
                    color={"white"}
                    onClick={toAboutScroll}
                />
            </div>
            <div className='abt_container'>
                <div className='abt_copy'>
                    <p className='abt_p_top'>
                        <span style={{ color: "#663873" }}>
                            Aviro, with support from the National HIV Think Tank
                            (National Department of Health & Foundation for
                            Professional Development), have developed HIV Engage
                            to support your HIV testing journey.
                        </span>
                    </p>
                </div>
                <div className='abt_iframe_container' ref={aboutRef}>
                    <iframe
                        className='abt_iframe'
                        title='About'
                        src='https://player.vimeo.com/video/410936509'
                        frameBorder='0'
                        allow='autoplay; fullscreen'
                        allowFullScreen
                    ></iframe>
                </div>
                <div className='abt_copy-bottom'>
                    <p className='abt_p'>
                        Whether you're a healthcare worker or end-user, we share
                        stories and provide accurate information that will guide
                        you in understanding HIV and how it relates to your
                        life, guiding you towards decision that will benefit
                        your health. We aim to reach you where you are, which is
                        why HIV Engage comes in 4 South African languages which
                        you can select from.
                    </p>
                </div>

                <div className='abt_button_container'>
                    <button className='abt_button' onClick={toHomeScroll}>
                        GET STARTED
                    </button>
                </div>
                <img src={Banner} alt='Banner' className='abt_banner' />
                <PageFooter />
            </div>
        </Fragment>
    );
};

export default React.memo(LandingContent);
