import React, { useState, useEffect, Fragment } from "react";
import firestore from "../../logic/firestore";
import { anonSignIn, sendMessage } from "../../logic/userLogic";
import TopNavigation from "../UI/TopNavigation";
import ContactForm from "../forms/ContactForm";
import Loading from "../UI/Loading";
import Menu from "../UI/Menu";

const Contact = () => {
    // Instantiate burger state for menu toggle
    const [burger, setBurger] = useState(false);

    // State to switch between form and success component
    // depending on whether or not a message has been sent
    const [sent, setSent] = useState(false);

    // Check if user is signed in or not
    useEffect(() => {
        const auth = firestore.auth();
        auth.onAuthStateChanged((user) => {
            if (!user) {
                // Sign user in
                anonSignIn();
            }
        });
    }, []);

    // Submit helper function
    const submitHelper = (data) => {
        // Sent message to firestore
        sendMessage(data);
        // Change UI
        setSent(true);
    };

    // Contact form UI helper function. Just to
    // make things a bit more granular and easier
    // to read at a glance
    const contactRender = () => {
        if (!sent) {
            return <ContactForm passDataUp={submitHelper} />;
        } else {
            return <Loading />;
        }
    };

    // #################### Burger Menu Toggle #################### //

    if (!burger) {
        return (
            <Fragment>
                <TopNavigation
                    burger={burger}
                    menuTrigger={() => setBurger(!burger)}
                />
                {contactRender()}
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <TopNavigation
                    burger={burger}
                    menuTrigger={() => setBurger(!burger)}
                />
                <Menu />
            </Fragment>
        );
    }
};

export default Contact;
